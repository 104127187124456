﻿@use "sass:math";
%container,
.container {
	max-width: ($container-max-width + $grid-gap);
	padding: rem(40px) math.div($grid-gap, 2) rem(40px);
	margin: 0 auto;

	&.large-padding {
		@include breakpoint(l) {
			padding: rem(80px) math.div($grid-gap, 2) rem(80px);
		}
	}

	&.largest-padding {
		padding: rem(80px) math.div($grid-gap, 2) rem(80px);

		@include breakpoint(l) {
			padding: rem(120px) math.div($grid-gap, 2) rem(120px);
		}
	}

	&.fluid {
		max-width: none;
		margin: 0;
	}

	&.small {
		max-width: ($container-small-max-width + $grid-gap);
	}

	&.med {
		max-width: ($container-med-max-width + $grid-gap);
	}

	&.med-alternate {
		max-width: ($container-med-alternate-max-width + $grid-gap);
	}

	&.large {
		max-width: ($container-large-max-width + $grid-gap);
	}
}
