﻿.home .header {
	figure, img {
		@include breakpoint(l) {
			height: 100vh !important;
		}
	}

	a {
		display: block;

		&:after {
			@include icon($icon-arrow-down);
			position: absolute;
			z-index: 2;
			left: 50%;
			bottom: 23%;
			transform: translateX(-50%);
			font-size: rem(19px);
			color: $color-default;

			@include breakpoint(lm) {
				bottom: 8%;
			}

			@include breakpoint(dl) {
				bottom: 7.5%;
			}
		}
	}

	.scroll-btn {
		position: absolute;
		display: flex;
		justify-content: center;
		align-items: center;
		text-align: center;
		width: rem(120px);
		height: rem(120px);
		background: $color-default;
		border-radius: 50%;
		z-index: 2;
		left: 50%;
		bottom: 20%;
		transform: translate(-50%, -50%);

		@include breakpoint(lm) {
			bottom: 5%;
		}

		h4 {
			font-size: rem(24px) !important;
			line-height: rem(20px) !important;
			font-weight: 400;
			color: $color-green !important;
			text-align: center !important;
			z-index: 1;
		}
	}
}
.header {
	position: relative;

	&:before {
		position: absolute;
		width: rem(1920px);
		height: 500px;
		left: 0;
		top: -330px;
		background-repeat: no-repeat;
		background-size: cover;
		background-image: url(../../images/transition/transition-bottom-white.svg);
		background-position: bottom;
		z-index: 1;
		content: '';
		min-width: 100vw;

		@include breakpoint(l) {
			top: -265px;
		}
	}

	&:after {
		position: absolute;
		width: rem(300px);
		height: rem(216px);
		right: 0;
		top: 0;
		background-repeat: no-repeat;
		background-size: cover;
		background-image: url(../../images/druppels-top.png);
		z-index: 1;
		content: '';

		@include breakpoint(ts) {
			width: rem(700px);
		}

		@include breakpoint(ll) {
			width: rem(1100px);
		}

		@include breakpoint(slider) {
			width: rem(1460px);
		}
	}

	figure {
		position: relative;
		height: calc(100vh - 56px) !important;

		@include breakpoint(l) {
			min-height: 62vh;
			height: auto !important;
		}

		figcaption {
			h1 {
				position: absolute;
				top: 40%;
				left: 50%;
				transform: translate(-50%, -50%);
				text-shadow: 0px 0px 22px rgba(0, 0, 0, 0.7);
				z-index: 1;
				width: 96vw;
				font-size: rem(40px);

				strong {
					font-size: rem(56px);
				}

				@include breakpoint(l) {
					top: 49%;
					font-size: rem(48px);

					strong {
						font-size: rem(72px);
					}
				}

				@include breakpoint(dl) {
					top: 45%;
				}
			}

			h2 {
				position: absolute;
				z-index: 1;
				bottom: 0;
				left: 50%;
				transform: translate(-50%, -50%);
				width: 90vw;

				@include breakpoint(lm) {
					width: 60vw;
					bottom: 3.5%;
					left: 75%;
					transform: translate(-50%, -50%);
				}
			}
		}
	}
}
