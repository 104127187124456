﻿section {
	position: relative;

	&.intro-block, &.growers-block {
		//padding-bottom: rem(96px);
		margin-bottom: rem(165px);

		@include breakpoint(t) {
			margin-bottom: rem(210px);
		}

		@include breakpoint(l) {
			padding-top: rem(96px);
			margin-bottom: rem(310px);
		}

		@include breakpoint(dl) {
			margin-bottom: rem(320px);
		}

		.content {
			position: relative;
			min-height: rem(200px);

			.container {
				position: relative;
				padding-top: 0;
				padding-bottom: 0;
				z-index: 1;
			}

			&:before {
				position: absolute;
				content: "";
				background-color: $color-green;
				width: 100%;
				height: 100%;
				left: 0;
				top: 0;

				@include breakpoint(l) {
					top: -7vh;
				}

				@include breakpoint(dl) {
					top: -10vh;
				}
			}

			&:after {
				position: absolute;
				height: 500px;
				right: 0;
				bottom: -100px;
				background-repeat: no-repeat;
				background-size: cover;
				background-position: bottom;
				z-index: 0;
				content: '';
				min-width: 100vw;
				min-height: 500px;
				background-image: url(../../images/transition/transition-bottom.svg);

				@include breakpoint(l) {
					width: rem(1920px);
					height: 53.53vh;
					bottom: -250px;
				}

				@include breakpoint(dl) {
					bottom: -277.5px;
				}
			}

			.products {
				.column {
					div {
						.button {
							position: absolute;
							top: 50%;
							left: 50%;
							transform: translate(-50%, -50%);
							opacity: 0;
							transition: opacity .5s ease-in-out;
							pointer-events: none;
							color: $color-default;
						}

						&:hover {
							.button {
								opacity: 1;
								transition: opacity .5s ease-in-out;
							}
						}
					}
				}
			}

			.products, .growers {
				padding-top: rem(56px);
				padding-right: 0;
				padding-left: 0;

				.columns {
					margin: 0;
				}

				.column {
					overflow: visible;
					margin-bottom: rem(48px);
					z-index: 1;

					div {
						position: relative;
						width: 100%;
						height: 100%;
						//background: $color-default;
						border-radius: 20px;

						h3, span {
							line-height: rem(32px);
							color: $color-green;
							text-align: center;
						}

						h3 {
							font-size: rem(28px);
							margin-bottom: rem(4px);
							line-height: rem(30px);

							@include breakpoint(l) {
								font-size: rem(32px);
							}
						}

						span {
							font-size: rem(24px);
							line-height: rem(28px);
						}

						figure {
							position: relative;

							figcaption {
								position: absolute;
								top: 9.5%;
								left: 50%;
								transform: translateX(-50%);
								display: flex;
								flex-direction: column;
								width: 80%;
							}
						}
					}
				}

				.placeholder {
					background-image: url(../../images/druppels-masked.jpg) !important;
					background-repeat: no-repeat !important;
					background-size: cover !important;
					background-position: right !important;
					z-index: 1;

					figcaption {
						justify-content: center;
						top: 50% !important;
						transform: translate(-50%, -50%) !important;
						height: 100%;

						h3 {
							position: relative !important;
							color: $color-default !important;
							font-size: rem(48px) !important;
							line-height: rem(48px) !important;
							font-weight: 400;
							margin-bottom: rem(32px);

							&.has-logo {
								font-size: rem(35px) !important;
								line-height: rem(35px) !important;
								margin-bottom: rem(24px);
							}
						}

						.button {
							top: unset !important;
							position: relative !important;
							color: $color-green !important;
							opacity: 1 !important;
							left: 50%;
							transform: translateX(-50%);
						}
					}

					@media(hover: hover) and (pointer: fine) {
						&:hover {
							.button {
								background: $color-green !important;
								color: $color-default !important;
								transition: all .15s ease;
							}
						}
					}
				}
			}
		}

		&:before {
			position: absolute;
			width: rem(1920px);
			height: 480px;
			right: 0;
			top: -240px;
			background-image: url(../../images/transition/transition-top.svg);
			background-repeat: no-repeat;
			background-size: cover;
			z-index: 0;
			content: '';
			min-width: 100vw;
			min-height: rem(480px);

			@include breakpoint(l) {
				top: -256.6px;
			}

			@include breakpoint(dl) {
				top: -400px;
			}
		}
	}

	&.growers-block {

		.growers {
			.column:nth-child(odd) figure {
				&:after {
					transform: scaleX(-1);
				}
			}

			.placeholder-figure {
				left: 50%;
				transform: translateX(-50%);
				width: 50%;
				height: max-content;

				img {
					object-fit: contain;
				}
			}

			.growers-div {
				cursor: pointer;

				.growers-figure {
					overflow: hidden;

					img {
						border-radius: 20px;
					}
				}

				&:hover {

					.growers-figure img {
						transform: scale(1.1);
						transition: all .375s ease;
					}

					.growers-btn {
						background: $color-green;
						transition: color .15s ease;

						&:after {
							color: $color-default;
							transition: color .15s ease;
						}
					}
				}
			}

			.growers-figure {
				position: relative;
				height: rem(432px);

				&:after {
					position: absolute;
					width: 100%;
					height: 200px;
					left: 0;
					bottom: 0;
					background-image: url(../../images/transition/transition-growers-large.svg);
					background-repeat: no-repeat;
					background-size: cover;
					z-index: 0;
					content: '';
				}

				img {
					height: auto !important;
					min-height: 66.67%;
					transition: all .25s ease-in-out;
				}

				figcaption {
					top: 73% !important;
					z-index: 1;
					max-width: 80%;

					@include breakpoint(ts) {
						max-width: 50%;
					}

					@include breakpoint(l) {
						top: 73% !important;
						max-width: 80%;
					}

					h3 {
						font-size: rem(26px) !important;
						word-break: break-word;
						width: 100%;
					}
				}
			}

			.growers-column {
				.button {
					position: absolute;
					width: rem(48px);
					height: rem(48px);
					border-radius: 50%;
					background-image: unset;
					background-color: $color-default;
					margin: unset;
					right: 12px;
					bottom: 12px;
					z-index: 1;
					padding: unset;
					transition: color .15s ease;

					&:after {
						@include icon($icon-info);
						position: absolute;
						font-size: rem(20px);
						color: $color-green;
						top: 50%;
						left: 50%;
						transform: translate(-50%,-50%);
						transition: color .15s ease;
					}

					&:hover {
						background: $color-green;
						transition: color .15s ease;

						&:after {
							color: $color-default;
							transition: color .15s ease;
						}
					}
				}
			}
		}

		.modal {
			position: fixed;
			top: 0;
			left: 0;
			width: 100%;
			max-width: 100vw;
			height: 100%;
			max-height: 100vh;
			background: rgba($color-green, 0.75);
			z-index: 1002;
			transition: all .25s ease;

			&.hide-modal {
				opacity: 0;
				pointer-events: none;
				transition: all .25s ease;
			}

			.swiper-btns {
				top: 0;
				width: 100%;
				height: 100%;

				.swiper-button-prev {
					top: 50%;
					transform: translateY(-50%);
					left: 0;
					z-index: 1;

					@include breakpoint(ts) {
						left: 8px;
					}

					@include breakpoint(l) {
						left: calc(50% - 364px);
					}
				}

				.swiper-button-next {
					top: 50%;
					transform: translateY(-50%);
					right: 0;
					left: unset;
					z-index: 1;

					@include breakpoint(ts) {
						right: 8px;
					}

					@include breakpoint(l) {
						right: calc(50% - 364px);
					}
				}
			}

			.swiper-slide {

				.slide-content .slide-close {
					position: absolute;
					width: rem(48px);
					height: rem(48px);
					border-radius: 50%;
					background-image: unset;
					background-color: $color-default;
					margin: unset;
					right: 12px;
					top: 12px;
					z-index: 1;
					padding: unset;
					transition: color .15s ease;
					cursor: pointer;

					&:after {
						@include icon($icon-close);
						position: absolute;
						font-size: rem(20px);
						color: $color-green;
						top: 50%;
						left: 50%;
						transform: translate(-50%,-50%);
						transition: color .15s ease;
					}

					&:hover {
						background: $color-green;
						transition: color .15s ease;

						&:after {
							color: $color-default;
							transition: color .15s ease;
						}
					}
				}

				.slide-content {
					position: relative;
					max-height: 85vh;
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					width: 75vw;
					max-width: 90vw;
					background: $color-default;
					border-radius: 20px;
					overflow: auto;

					@include breakpoint(l) {
						width: rem(600px) !important;
					}

					.image {
						border-bottom-left-radius: unset;
						border-bottom-right-radius: unset;
					}

					.text-content {
						padding: rem(24px);

						h3 {
							position: relative;
							padding-bottom: rem(18px);
							font-size: rem(28.8px);
							color: $color-green;
						}

						p, div {
							color: #000;
						}

						p, div {
							margin-bottom: 1.5rem;
							font-size: rem(20px);
							line-height: rem(24px);
						}

						ul {
							position: relative;

							&:after {
								position: absolute;
								content: "";
								background: #000;
								opacity: .1;
								top: 0;
								left: 0;
								width: 100%;
								height: 1px;
							}

							li {
								list-style: none;
								position: relative;
								padding: rem(8px) 0;
								display: flex;
								flex-direction: column;

								@include breakpoint(l) {
									flex-direction: unset;
								}

								label {
									width: 40%;
									color: #000;
									font-weight: 600;
									font-size: rem(18px);
									line-height: rem(22px);
								}

								p, div {
									margin-bottom: 0;
									width: 60%;
								}

								&:after {
									position: absolute;
									content: "";
									background: $color-green;
									opacity: .1;
									bottom: 0;
									left: 0;
									width: 100%;
									height: 1px;
								}
							}
						}
					}
				}
			}
		}
	}

	&.text-block {
		background-color: $color-default;

		figure {
			margin-top: rem(32px);
		}

		@include breakpoint(l) {
			.column:first-child {
				padding-right: rem(96px);
			}

			figure {
				margin-top: 0;
			}

			.container {
				padding-bottom: rem(160px);
			}
		}
	}

	&.main-products-block {
		background-color: $color-default;
		z-index: 2;

		h2 {
			color: $color-green;
			margin-bottom: rem(40px);

			@include breakpoint(l) {
				margin-bottom: rem(76px);
			}
		}

		.column {
			height: rem(576px);
			overflow: visible;
			margin-bottom: rem(40px);

			@include breakpoint(ds) {
				margin-bottom: 0;
			}

			@include breakpoint(l) {
				margin-bottom: rem(120px);
			}

			div {
				position: relative;
				overflow: visible;
				width: 100%;
				height: 100%;
				border-radius: 20px;
				background-image: url(../../images/druppels-masked.jpg);
				background-repeat: no-repeat;
				background-size: cover;
				background-position: right;

				h3 {
					padding-top: rem(32px);
					font-size: rem(40px);
					line-height: rem(40px);
					color: $color-default;
					text-align: center;
				}

				figure {
					position: absolute;
					left: 50%;
					top: 63.5%;
					transform: translate(-50%, -50%) scale(0.7);
					width: rem(564px);
					height: rem(564px);

					@include breakpoint(ts) {
						transform: translate(-50%, -50%);
					}
				}

				span {
					position: absolute;
					top: 65%;
					left: 50%;
					transform: translate(-50%, -50%);
					opacity: 0;
					transition: opacity .25s ease-in-out;
					pointer-events: none;
				}

				&:hover {
					span {
						opacity: 1;
						transition: opacity .25s ease-in-out;
					}
				}
			}
		}
	}

	&.partner-products-block {
		background-color: $color-default;

		h2 {
			color: $color-green;
			margin-bottom: rem(52px);
		}

		.column {
			height: rem(324px);
			overflow: visible;
			margin-bottom: rem(40px);

			div {
				position: relative;
				overflow: visible;
				width: 100%;
				height: 100%;
				border-radius: 20px;
				background-image: url(../../images/druppels-masked.jpg);
				background-repeat: no-repeat;
				background-position: 0% 100%;
				background-size: 210% 210%;

				a {
					position: relative;
				}

				h3 {
					padding-top: rem(29px);
					line-height: rem(40px);
					color: $color-default;
					text-align: center;
				}

				figure {
					position: absolute;
					left: 50%;
					top: 63.5%;
					transform: translate(-50%, -50%);
					width: rem(400px);
					height: rem(400px);

					@include breakpoint(l) {
						left: 58%;
					}
				}

				span {
					position: absolute;
					top: 60%;
					left: 50%;
					transform: translate(-50%, -50%);
					opacity: 0;
					transition: opacity .25s ease-in-out;
					pointer-events: none;
				}

				&:hover {
					span {
						opacity: 1;
						transition: opacity .25s ease-in-out;
					}
				}
			}
		}
	}

	&.slider-block {
		position: relative;

		.slider-image {
			img {
				visibility: hidden;
			}
		}

		.seperate-image {
			display: block;
		}

		@include breakpoint(l) {
			min-height: 100vh;
			height: 100%;
			max-height: rem(1080px);

			.slider-image {
				img {
					visibility: visible;
				}
			}

			.seperate-image {
				display: none;
			}
		}

		@media screen and (orientation:landscape) {
			@include breakpoint(l) {
				min-height: rem(1080px);

				.swiper-container, figure, img, .swiper-slide:before {
					min-height: rem(1080px);
				}
			}
		}

		.swiper-container {
			@include breakpoint(l) {
				min-height: 100vh;
				height: 100%;
				max-height: rem(1080px);
			}
		}

		h2 {
			position: absolute;
			top: rem(120px);
			left: rem(24px);
			z-index: 2;

			@include breakpoint(slider) {
				left: rem(120px);
			}
		}

		.swiper-slide {
			position: relative;

			&:before {
				position: absolute;
				width: rem(1920px);

				@include breakpoint(l) {
					min-height: 100vh;
					height: 100%;
				}

				max-height: rem(1080px);
				left: 0;
				top: 0;
				background-repeat: no-repeat;
				background-size: cover;
				background-position: right;
				z-index: 1;
				content: '';
				min-width: 41.6vw;
				background-image: url(../../images/transition/transition-vertical.svg);

				@include breakpoint(l) {
					width: rem(799px);
				}
			}
		}

		.swiper-pagination {
			bottom: unset;
			top: rem(250px);
			left: rem(24px);
			text-align: left;

			@include breakpoint(slider) {
				left: rem(120px);
			}
		}

		figure, img {
			min-height: 100vh;
			height: 100%;
			max-height: rem(1080px);
		}

		figure {
			position: relative;

			figcaption {
				position: absolute;
				max-width: rem(560px);
				top: rem(350px);
				color: $color-default;
				z-index: 1;

				h3 {
					color: $color-default;
				}

				p {
					padding-bottom: rem(32px);
				}

				padding-left: rem(24px);
				padding-right: rem(24px);

				@include breakpoint(slider) {
					padding-left: rem(120px);
				}
			}
		}

		img {
			object-fit: cover;
		}
	}

	&.growers-information-block {
		background: $color-lightgreen;
		margin-bottom: rem(48px);

		.container {
			padding-top: rem(144px);
		}

		.button {
			margin: 0 0 rem(32px) 0;
			left: 50%;
			transform: translateX(-50%);

			@include breakpoint(l) {
				margin-bottom: 0;
			}
		}

		@include breakpoint(l) {
			margin-bottom: rem(120px);
		}

		h2 {
			color: $color-green;
			margin-bottom: rem(64px);
			text-align: center;
		}

		ul {
			display: flex;
			flex-wrap: wrap;

			@include breakpoint(l) {
				margin-bottom: rem(48px) !important;
			}

			li {
				display: flex;
				flex-direction: column;
				align-items: center;
				list-style: none;
				margin-bottom: rem(20px);
				width: 50%;
				margin-bottom: rem(56px);

				@include breakpoint(l) {
					width: 20%;
					margin: unset;
				}

				div {
					display: block;
					transition: all .15s ease;
					background: $color-default;
					width: rem(104px);
					height: rem(104px);
					border-radius: 50%;
					position: relative;
					margin-bottom: rem(16px);

					figure {
						position: absolute;
					}
				}

				.grower-title {
					color: $color-green;
					font-size: rem(28px);
					line-height: rem(28px);
					text-align: center;
					max-width: 70%;
					margin-bottom: 0;
					transition: color .15s ease;

					@include breakpoint(l) {
						font-size: rem(32px);
						line-height: rem(32px);
					}

					br {
						display: none;
					}
				}
			}
		}

		.column:last-child {
			figure {
				position: relative;
				height: 100%;

				@media(hover: hover) and (pointer: fine) {
					&:hover {
						img {
							transform: scale(1.15);
							transition: all .375s ease;
						}
					}
				}

				img {
					transition: all .25s ease-in-out;
				}

				figcaption {
					position: absolute;
					width: 100%;
					top: 50%;
					//left: 50%;
					transform: translateY(-50%);
					display: flex;
					flex-direction: column;
					align-items: center;

					span {
						@include font-caption;
						text-shadow: 0px 0px 22px rgba(0, 0, 0, 0.7);
						max-width: 80%;
						margin-bottom: rem(8px);
					}
				}
			}
		}

		&:after {
			position: absolute;
			width: 101vw;
			height: 4.427vw;
			left: 0;
			top: 99.8%;
			background-image: url(../../images/transition/transition-bottom-lightgreen.svg);
			background-repeat: no-repeat;
			background-size: cover;
			z-index: 1;
			content: '';
		}
	}

	&.information-block {
		background-color: $color-default;

		h2 {
			color: $color-green;
			margin-bottom: rem(64px);
		}

		ul {
			display: flex;
			flex-wrap: wrap;
			flex-direction: column;
			margin-bottom: rem(32px);

			@include breakpoint(l) {
				margin-bottom: 0;
			}

			li {
				display: flex;
				align-items: center;
				list-style: none;
				margin-bottom: rem(20px);

				div {
					position: relative;
					background: $color-lightgreen;
					width: rem(104px);
					height: rem(104px);
					border-radius: 50%;

					figure {
						position: absolute;
						top: 50%;
						left: 50%;
						transform: translate(-50%, -50%);
					}
				}

				p {
					color: $color-green;
					font-size: rem(28px);
					line-height: rem(28px);
					padding-left: rem(32px);
					max-width: 70%;
					margin-bottom: 0;

					@include breakpoint(l) {
						font-size: rem(32px);
						line-height: rem(32px);
					}
				}
			}
		}

		.column:last-child {
			figure {
				position: relative;
				height: 100%;

				figcaption {
					position: absolute;
					width: 100%;
					top: 50%;
					//left: 50%;
					transform: translateY(-50%);
					display: flex;
					flex-direction: column;
					align-items: center;

					span {
						@include font-caption;
						text-shadow: 0px 0px 22px rgba(0, 0, 0, 0.7);
						max-width: 80%;
						margin-bottom: rem(8px);
					}
				}

				&:hover {
					.button {
						background-color: $color-green;
						color: $color-default;
						transition: all .25s ease-in-out;
					}
				}
			}
		}
	}

	&.image-text-block {

		&:before {
			position: absolute;
			height: 27.5vh;
			right: 0;
			top: 0;
			background-repeat: no-repeat;
			background-size: cover;
			background-position: center;
			content: '';
			min-width: 100vw;
			min-height: 13.38vw;
			background-image: url(../../images/transition/transition-top-alternate.svg);

			@include breakpoint(dm) {
				background-position: right;
			}
		}

		&:after {
			position: absolute;
			right: 0;
			bottom: -1px;
			background-repeat: no-repeat;
			background-size: cover;
			background-position: bottom;
			z-index: 1;
			content: '';
			min-width: 100vw;
			min-height: 13.38vw;
			background-image: url(../../images/transition/transition-bottom-alternate.svg);
			width: 100vw;
			height: 100px;

			@include breakpoint(l) {
				width: rem(200px);
				height: 27.5vh;
				background-position: right;
			}

			@include breakpoint(dm) {
				background-position: bottom;
			}
		}

		figure {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;

			img {
				object-fit: cover;
				height: 100%;
			}

			z-index: -1;
		}

		.container {
			height: 100%;
			padding-top: rem(110px);
			padding-bottom: rem(238px);

			.column {
				position: relative;
				width: 100%;
				padding: rem(64px);

				@include breakpoint(l) {
					width: rem(552px);
				}

				h2 {
					line-height: rem(32px);
					margin-bottom: rem(32px);
				}

				p {
					color: $color-default;
				}

				a {
					cursor: pointer;
					z-index: 2;
					margin-right: rem(20px);
					transition: all .25s ease-in-out;

					&:hover {
						background-color: $color-green;
						color: $color-default;
						transition: all .25s ease-in-out;
					}
				}

				&:before {
					position: absolute;
					top: 0;
					left: 0;
					border-radius: 24px;
					content: "";
					width: 100%;
					height: 100%;
					opacity: .9;
					background-image: -moz-linear-gradient(180deg, rgb(30,128,63) 0%, rgb(147,200,62) 100%);
					background-image: -webkit-linear-gradient(180deg, rgb(30,128,63) 0%, rgb(147,200,62) 100%);
					background-image: -ms-linear-gradient(180deg, rgb(30,128,63) 0%, rgb(147,200,62) 100%);
					background-image: linear-gradient(180deg, rgb(30,128,63) 0%, rgb(147,200,62) 100%);
				}
			}
		}
	}
}
