﻿.dropdown {
	position: relative;
	height: rem(56px);
	display: flex;
	justify-content: center;
	margin: 0 auto;
	z-index: 3;

	dl::selection {
		color: none;
		background: none;
	}

	dl {
		width: rem(265px);

		dt:focus {
			outline: 0;
		}

		a {
			position: relative;
			display: block;
			transition: all 0.25s ease;
			transition-delay: .25s;
		}

		dt label {
			position: absolute;
			width: rem(265px);
			background: $color-default;
			white-space: nowrap;
			cursor: pointer;
			outline: none !important;
			overflow: hidden;
			text-overflow: ellipsis;
			text-align: center;
			font-weight: 700;
			color: $color-green;
			border-radius: 24px;
			padding: rem(6.5px) rem(80px) rem(9.5px) rem(30px);
			z-index: 2;

			&:after {
				@include icon($icon-arrow-down);
				position: absolute;
				right: 18px;
				top: 50%;
				transform: translate(-50%, -50%);
				text-indent: 0;
				color: $color-green;
				font-size: 12px;
				font-weight: 400;
				transition: all .25s ease-in-out;
			}

			&:hover, &:focus, &:active {
				background-color: darken($color-default, 10%);
			}
		}

		dd {
			position: absolute;
			opacity: 0;
			pointer-events: none;
			margin-top: rem(48px);
			width: rem(265px);
			z-index: 2;

			ul {
				margin: 0 !important;

				&:before {
					display: none !important;
				}

				li {
					list-style: none;
					padding: 0;
					background-color: $color-default;
					font-weight: 700;

					a {
						color: $color-green;
						overflow: hidden;
						padding: rem(4px) rem(40px);
						text-align: center;
						text-overflow: ellipsis;

						&:before {
							position: absolute;
							width: 100%;
							height: 1px;
							content: '';
							background: $color-green;
							opacity: .2;
							left: 0;
							top: 0;
						}
					}

					&:hover, &:focus, &:active {
						background-color: darken($color-default, 10%);
					}
				}

				li:last-child {
					border-bottom-left-radius: 24px;
					border-bottom-right-radius: 24px;
				}
			}
		}
	}

	input[type=checkbox] {
		display: none;

		&:checked {
			~ dl dd {
				opacity: 1;
				pointer-events: all;
				transition: all 0.25s ease;
			}

			~ dl dt label {
				border-bottom-left-radius: 0;
				border-bottom-right-radius: 0;
				transition: all 0.05s ease;

				&:after {
					transform: translate(-50%, -50%) rotate(180deg);
				}
			}
		}
	}
}
