﻿@import "fonts";
@import "icon";

// Primary colors
$color-default: #ffffff;
$color-green: #1e803f;
$color-green-2: #41963f;
$color-lightgreen: #93c83e;
$color-grey: #888888;
$transition-time: 1.5s;

// Base
$font-text: "Cambria", sans-serif;
$default-line-height: rem(32px);

// Container
$container-max-width: rem(1536px);
$container-small-max-width: rem(1160px);
$container-med-max-width: rem(1392px);
$container-med-alternate-max-width: rem(1440px);
$container-large-max-width: rem(1536px);

// Grid
$grid-columns: 12;
$grid-gap: 3rem;
