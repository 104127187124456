﻿$hamburger-padding-x: 0;
$hamburger-padding-y: 0;
$hamburger-layer-width: 20px;
$hamburger-layer-height: 2px;
$hamburger-layer-spacing: 5px;
$hamburger-layer-color: $color-green;
$hamburger-layer-border-radius: 0;
$hamburger-hover-opacity: 1;
$hamburger-hover-transition-duration: 0.3s;
$hamburger-hover-transition-timing-function: ease-in-out;

@import "~hamburgers/_sass/hamburgers/hamburgers";

#menu {
	&:checked + label {
		@extend .is-active;
	}
}

.hamburger {
	background: $color-default;
	position: fixed;
	z-index: 1001;
	transition: all $transition-time ease-in-out, background $transition-time ease-in-out, color $transition-time ease-in-out;
	height: 56px;
	width: 100%;
	padding: 0 10px;
	border: none;
	right: 0;
	bottom: 0;
	cursor: pointer;
	opacity: 1;
	outline: none;
	transition: all .5s ease;

	@media screen and (min-width: 1024px) {
		width: rem(60px);
		height: rem(60px);
		border-radius: 50%;
		top: rem(30px);
		right: rem(30px);
		border: 1px solid darken(#fff, 15%);
	}

	.hamburger-box {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}

	.hamburger-inner {
		width: 24px;
		left: -2px;
	}

	.hamburger-inner:before, .hamburger-inner:after {
		left: 2px;
	}

	.hamburger-inner,
	.hamburger-inner:before,
	.hamburger-inner:after {
		transition: all 0.3s ease-in-out;
	}

	&.hamburger--spin.is-active {
		.hamburger-inner {
			left: 0;
			width: 20px;
		}

		.hamburger-inner:before {
			left: 0;
		}

		.hamburger-inner:after {
			left: 0;
			transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1), background 0.3s ease-in-out;
		}
	}

	@media(hover: hover) and (pointer: fine) {
		&:hover {
			background: $color-green;
			border: none;

			.hamburger-inner,
			.hamburger-inner:before,
			.hamburger-inner:after {
				transition: all 0.25s ease-in-out;
				background: $color-default !important;
			}
		}
	}
}
