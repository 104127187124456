﻿@font-face {
	font-family: 'Cambria';
	src: url('../../fonts/cambria.ttf');
	font-weight: 400;
	font-style: normal;
	font-stretch: normal;
	font-display: block;
}

@font-face {
	font-family: 'Cambria';
	src: url('../../fonts/cambriabi.ttf');
	font-weight: 400;
	font-style: italic;
	font-stretch: normal;
	font-display: block;
}

@font-face {
	font-family: 'Cambria';
	src: url('../../fonts/cambriab.ttf');
	font-weight: 700;
	font-style: normal;
	font-stretch: normal;
	font-display: block;
}

@font-face {
	font-family: 'Cambria';
	src: url('../../fonts/cambriabi.ttf');
	font-weight: 700;
	font-style: italic;
	font-stretch: normal;
	font-display: block;
}

@font-face {
	font-family: 'Chaparral Pro';
	src: url('../../fonts/ChaparralPro-Regular.ttf');
	font-weight: 400;
	font-stretch: normal;
	font-display: block;
}

@mixin font-text {
	font-family: $font-text;
	font-size: rem(24px);
	line-height: rem(32px);
	font-weight: 400;
	color: $color-grey;
}

@mixin font-button {
	font-family: $font-text;
	font-weight: 700;
}

@mixin font-footer {
	font-family: $font-text;
	font-size: rem(16px);
	line-height: rem(40px);
	color: $color-default;
}

@mixin font-button {
	font-family: $font-text;
	color: $color-default;
	font-weight: 700;
}

@mixin font-caption {
	font-family: $font-text;
	color: $color-default;
	font-weight: 400;
	font-size: rem(48px);
	line-height: rem(48px);
}

@mixin font-navigation {
	font-family: 'Chaparral Pro';
	font-weight: 400;
}