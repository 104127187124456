﻿@import '~swiper/swiper';
@import '~swiper/components/navigation/navigation';
@import '~swiper/components/pagination/pagination';
@import '~swiper/components/effect-fade/effect-fade';

//swiper
.swiper-button-prev.swiper-button-disabled, .swiper-button-next.swiper-button-disabled {
	background: $color-green !important;
	border: 2px solid $color-green-2 !important;

	&:after {
		color: $color-green-2 !important;
	}
}

.slider-block {
	.swiper-container {
		position: relative;
		background-color: $color-green;
	}
}

.swiper-pagination-bullet {
	width: 40px;
	height: 40px;
	text-align: center;
	line-height: 32px;
	font-size: 24px;
	font-weight: 700;
	color: $color-default;
	opacity: 1;
	background: $color-green;
	border: 2px solid $color-default;
	margin: 0 rem(18px) 0 0 !important;
}

.swiper-pagination-bullet:hover{
	background:$color-green-2;
}

.swiper-pagination-bullet-active {
	color: $color-green !important;
	background: $color-default !important;
}

.swiper-btns {
	position: absolute;
	width: 100%;
	//top: 50%;

	.swiper-button-prev {
		left: unset;

		@media(hover: hover) and (pointer: fine) {
			&:hover {
			}
		}

		&:after {
			left: 45%;
			@include icon($icon-arrow-left);
		}
	}

	.swiper-button-next {
		left: rem(58px);

		@media(hover: hover) and (pointer: fine) {
			&:hover {
			}
		}

		&:after {
			left: 55%;
			@include icon($icon-arrow-right);
		}
	}

	.swiper-button-prev, .swiper-button-next {
		position: absolute;
		width: rem(48px);
		height: rem(48px);
		background-color: $color-lightgreen;
		opacity: 1;
		border-radius: 50%;

		&:after {
			position: absolute;
			top: 50%;
			transform: translate(-50%, -50%);
			font-size: rem(16px);
			color: $color-green;
		}

		@media(hover: hover) and (pointer: fine) {
			&:hover {
			}
		}
	}
}
