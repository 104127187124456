﻿section {
	.image {
		border-radius: 20px;
	}
}

.image {
	&.is-square {
		@include responsive-container-psudo(1, 1);
	}

	&.is-3by2 {
		@include responsive-container-psudo(3, 2);
	}

	&.is-16by9 {
		@include responsive-container-psudo(16, 9);
	}

	&.is-header-small {
		@include responsive-container-psudo(3.31, 1);
	}

	&.is-075by1 {
		@include responsive-container-psudo(0.75, 1);
	}

	img, &div, video {
		width: 100%;
		height: auto;
	}
}

.has-hover {
	height: 100%;
	display: block;

	img {
		transition: all .25s ease-in-out;

		@media(hover: hover) and (pointer: fine) {
			&:hover {
				transform: scale(1.1);
				transition: all .375s ease;
			}
		}
	}
}
