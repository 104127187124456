﻿@mixin underline {
	position: relative;
	width: max-content;
	display: block;

	&:after {
		content: "";
		position: absolute;
		left: 0;
		right: 100%;
		top: 28px;
		background: $color-default;
		height: 1px;
		transition-property: left right;
		transition: all .25s ease-out;
	}

	&:hover, &:focus, &:active {

		@media(hover: hover) and (pointer: fine) {
			&:after {
				right: 0;
			}
		}
	}
}
