﻿.button {
	@include font-button;
	user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-webkit-tap-highlight-color: transparent;
	outline: none;
	width: max-content;
	max-width: 100%;
	position: relative;
	z-index: 0;
	display: inline-flex;
	padding: 6.5px 30px 9.5px 30px;
	margin: rem(16px) rem(24px) 0 0;
	overflow: hidden;
	cursor: pointer;
	justify-content: center;
	align-items: center;
	box-shadow: none !important;
	border-radius: 24px;
	color: $color-default;
	background-image: linear-gradient(to right, $color-green 50%, $color-green-2 50%);
	background-size: 200% 100%;
	transition: background-position .25s ease;

	&::selection, &::-moz-selection {
		color: none;
		background: none;
	}

	@media(hover: hover) and (pointer: fine) {
		&:hover {
			background-position: -100% 0;
		}
	}

	&:focus {
		outline: 0 !important;
	}

	&.external {
		padding-right: rem(56px);
		transition: all .25s ease-in-out;

		&:after {
			@include icon($icon-external);
			position: absolute;
			display: flex;
			justify-content: end;
			margin-top: rem(1px);
			font-size: rem(16px);
			right: rem(30px);
			text-indent: 0;
		}
	}

	&.alternate {
		background: $color-default;
		color: $color-green;
	}
}

.buttons {
	margin-top: rem(-16px);
}