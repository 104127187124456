﻿nav {
	input {
		display: none;
	}

	.menu {
		position: fixed;
		display: flex;
		width: 100vw;
		height: 100vh;
		right: -125%;
		transition: all .5s ease-in-out;
		z-index: 10;

		div {
			width: 100vw;
			height: 100%;
			background-color: #000;
			opacity: .3;
		}

		ul {
			position: absolute;
			top: 0;
			right: 0;
			display: flex;
			flex-direction: column;
			border-bottom-left-radius: rem(120px);
			background: #fff;
			z-index: 99;
			min-width: 95%;
			height: 95%;
			padding: 0 rem(80px) 0 rem(110px);
			justify-content: center;

			@media screen and (min-width: 1024px) {
				width: 20vw;
				height: auto;
				padding: rem(120px) rem(80px) rem(74px) rem(110px);
				min-width: rem(385px);
				justify-content: unset;
			}

			li {
				@include font-navigation;
				list-style: none;
				transform: translateX(200%);
				opacity: 0;
				transition: transform 1s ease,opacity 1.5s ease;

				a {
					font-size: rem(32px);
					line-height: rem(52px);
					font-weight: 400;
					text-decoration: none;
					color: $color-green;

					@media(hover: hover) and (pointer: fine) {
						&:hover {
							color: darken(#fff, 15%);
						}
					}
				}
			}

			.languages {
				padding-top: rem(64px);

				a {
					position: relative;
					font-size: rem(24px);
					padding-right: rem(35px);

					&:not(:first-child) {
						&:before {
							position: absolute;
							content: "";
							background: #75a783;
							width: 2px;
							height: 24px;
							top: 50%;
							left: rem(-17.5px);
							transform: translate(-50%, -50%);
						}
					}
				}
			}
		}
	}

	#menu {
		display: none;

		&:checked + label {
			//	color: bl
		}

		&:checked ~ .menu {
			right: 0;
			transition: all .5s ease-in-out;

			li {
				transform: translateX(0);
				transition: transform 1s ease,opacity 1.5s ease;
				opacity: 1;
			}
		}

		&:checked + #hamburger {
			//opacity: 1;
			//visibility: visible;
		}

		@media screen and (min-width: 1024px) {
			&:checked ~ #hamburger {
				border: none;

				.hamburger-inner {
					//background-color: #fff;

					&:before, &:after {
						//background-color: #fff;
					}
				}
			}
		}
	}
}
