﻿nav {
	.nav-tel, .nav-mail {
		position: fixed;
		width: rem(60px);
		height: rem(60px);
		border-radius: rem(30px);
		top: rem(30px);
		background: $color-green;
		transition: all .25s ease-in-out;
		z-index: 3;
		border: 1px solid darken($color-green, 5%);
		overflow: hidden;

		span {
			opacity: 0;
			height: 1px;
			display: block;
		}

		&:after {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			color: $color-default;
			transition: all .25s ease-in-out;
		}

		@media(hover: hover) and (pointer: fine) {
			&:hover {
				cursor: pointer;
				border: 1px solid #d9d9d9;
				background: $color-default;

				&:after {
					left: rem(30px);
					color: $color-green;
					transition: all .25s ease-in-out;
				}

				display: flex;
				//width: auto;
				justify-content: center;
				flex-direction: column;
				padding-left: rem(50px);

				span {
					position: relative;
					top: rem(-2px) !important;
					color: $color-green;
					opacity: 1;
					height: auto;
					width: rem(300px);
					transition-delay: .25s;
				}
			}
		}
	}

	.nav-tel {
		right: rem(30px);

		@include breakpoint(l) {
			right: rem(100px);
		}

		&:after {
			@include icon($icon-phone);
			font-size: rem(24px);
		}

		@media(hover: hover) and (pointer: fine) {
			&:hover {
				padding-right: rem(224px);

				span {
					width: rem(224px);
				}
			}
		}
	}

	.nav-mail {
		right: rem(100px);

		@include breakpoint(l) {
			right: rem(170px);
		}

		&:after {
			@include icon($icon-mail);
			font-size: rem(26px);
		}

		@media(hover: hover) and (pointer: fine) {
			&:hover {
				padding-right: rem(360px);

				span {
					width: rem(300px);
				}
			}
		}
	}
}
