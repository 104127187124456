﻿.logo {
	position: absolute;
	width: rem(220px);
	height: rem(100px);
	display: flex;
	background-size: contain;
	overflow: hidden;
	text-indent: -99999px;
	background-repeat: no-repeat;
	z-index: 3;
	margin: rem(32px) 0 0 rem(48px);
	background-image: url(../../images/logo.svg);

	@include breakpoint(l) {
		margin: rem(48px) 0 0 rem(96px);
	}
}
