﻿body {
	&.home {
		footer {
			&:after {
				display: none;
			}
		}
	}
}

footer {
	@include font-footer;
	background: $color-lightgreen;
	position: relative;

	&:before {
		position: absolute;
		width: rem(1920px);
		height: 320px;
		right: 0;
		bottom: rem(-100px);
		background-image: url(../../images/transition/transition-footer.svg);
		background-repeat: no-repeat;
		background-size: cover;
		z-index: 0;
		content: '';
		min-width: 100vw;
		min-height: rem(320px);

		@include breakpoint(l) {
			//bottom: rem(-170px);
			bottom: 0;
			min-height: rem(352px);
		}

		@include breakpoint(dl) {
			min-width: 100vw;
		}
	}

	.footer-block {
		padding-bottom: rem(64px);

		@include breakpoint(l) {
			padding-bottom: rem(110px);
		}
	}

	.columns {
		h2 {
			color: $color-green;
			margin-bottom: rem(32px);
		}

		.contact-block {
			display: flex;
			flex-wrap: wrap;
			margin-bottom: rem(32px);

			@include breakpoint(l) {
				margin-bottom: unset;
			}

			.mail {
				display: flex;
				flex-direction: column;
				flex-wrap: wrap;
				margin-top: rem(-8px);
			}

			.button {
				font-weight: 700;
				font-size: rem(24px);
				line-height: rem(32px);
				margin-bottom: rem(32px);

				@include breakpoint(l) {
					margin-bottom: unset;
				}
			}
		}

		.address-block {
			position: relative;
			display: flex;
			flex-direction: column;
			flex-wrap: wrap;
			padding-left: 92px;
			margin-bottom: rem(32px);

			@include breakpoint(l) {
				margin-bottom: rem(230px);
			}

			span:first-of-type {
				font-weight: 700;
			}

			&:before {
				position: absolute;
				content: "";
				width: 72px;
				height: 72px;
				background: $color-default;
				border-radius: 50%;
				left: 0;
				top: 50%;
				transform: translateY(-50%);
			}

			&:after {
				@include icon($icon-pin);
				position: absolute;
				font-size: 34px;
				color: $color-green;
				left: 36px;
				top: 50%;
				transform: translate(-17px, -50%);
			}
		}

		.linkedin-block {
			margin-bottom: rem(100px);

			@include breakpoint(l) {
				margin-bottom: unset;
			}

			a {
				position: relative;
				display: inline-flex;
				width: auto;

				&:before {
					position: absolute;
					content: "";
					width: 72px;
					height: 72px;
					background: $color-default;
					border-radius: 50%;
					left: 0;
					top: 50%;
					transform: translateY(-50%);
					transition: all .25s ease-in-out;
				}

				&:after {
					@include icon($icon-linkedin);
					position: absolute;
					font-size: 34px;
					color: $color-green;
					left: 36px;
					top: 50%;
					transform: translate(-17px, -50%);
					transition: all .25s ease-in-out;
				}

				h2 {
					color: $color-default;
					margin-bottom: 0;
					line-height: rem(29px);
					padding-left: 92px;
					transition: all .25s ease-in-out;
				}

				@media(hover: hover) and (pointer: fine) {
					&:hover {

						h2 {
							color: $color-green;
						}

						&:before {
							background: $color-green;
						}

						&:after {
							color: $color-default;
						}
					}
				}
			}
		}

		.member-block {
			margin-top: rem(-11px);

			a {
				position: relative;
				color: transparent;

				@include breakpoint(l) {
					padding-left: rem(92px);
					padding-top: rem(-10px);
				}

				&:before {
					position: absolute;
					content: "";
					background: url(../../images/member.svg);
					background-repeat: no-repeat;
					width: rem(241px);
					height: rem(92px);
				}
			}
		}

		span {
			color: $color-green;
			font-size: rem(24px);
			line-height: rem(32px);

			a {
				text-decoration: underline;

				&:hover {
					filter: brightness(75%);
				}
			}
		}
	}

	ul {
		display: flex;
		flex-wrap: wrap;
		padding-top: rem(8px) !important;
		padding-bottom: rem(8px) !important;
		margin-bottom: rem(80px) !important;

		li {
			margin-right: rem(18px);
			z-index: 0;

			a {
				@include underline;
			}
		}

		@include breakpoint(l) {
			margin-bottom: 0 !important;
		}
	}
}
